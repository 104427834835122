import { t } from "i18next"
import * as Yup from "yup"
export interface AssessmentOperationOptions {
  user_id: number | string | number | null
  facilityIds: any[]
  from_date: any
  to_date: any
  display_setting: any
  status: any[]
  mode?: string
}

export interface AssessmentEmploymentItem {
  company_id: number
  start_date?: string | null
  assessment_id?: number
  end_date?: string | null
  company_name?: string
  detail?: string
}
export interface AssessmentEntityItem {
  company_id: number
  assessment_id: number
  answer?: string | null
  assessment_item_id: number
  remarks?: string | null
}
export interface AssessmentFormValues {
  creation_date: string
  user_id: string | number | null
  facility_id: string | number | null
  service_type: string | number | null
  user_hiragana?: string
  staff_id: string | number | null
  status: boolean
  creation_number: number
  assessment_employment_item: AssessmentEmploymentItem[]
  assessment_entity_item?: AssessmentEntityItem[]
  [key: string]: any
}
export const AssessmentFormValuesValidationSchema = Yup.object().shape({
  user_id: Yup.string().required(t("Required")),
  facility_id: Yup.string().required(t("Required")),
  service_type: Yup.string().required(t("Required")),
  staff_id: Yup.string().required(t("Required")),
  creation_number: Yup.number().required(t("Required")).min(1, t("Required")),
})
export interface AssessmentTableDataChangeProps {
  value: string | number
  key: string
  id?: number
  category_id?: number
  item_id: number
  entry_id?: number
}
