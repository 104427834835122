import { t } from "i18next"
import * as yup from "yup"

export type UserManagementOperation = {
  keyword: string
  consultation_support_office_id: string | number
  provision_city_id: string | number
  service_used_by_user: Array<any>
  contract_year_month: string
  contract_facility: Array<any>
}

export type UserManagementInitialValues = {
  username: string
  furigana: string
  dob: string
  gender: string
  phone: string
  pobox: string
  pref_id: number
  address: string
  wage_style: string
  hourly_wage: number
  parent_id: number
  image: string
  financial_institution_name_kana: string
  branch_name_kana: string
  financial_institute_code: string
  branch_code: string
  account_type: number
  account_number: string
  account_holder_name: string
  customer_code: string
  email_address: string
  email_address_2: string
  attend_flag: boolean
  leave_flag: boolean
  emergency_contact_name1: string
  emergency_contact_phone1: string
  emergency_contact_name2: string
  emergency_contact_phone2: string
  emergency_contact_remarks: string
  hospital_name: string
  doctor_name: string
  hospital_ph: string
  allergy_flag: boolean
  allergy_remarks: string
  symptoms: string
  medical_info: string
  other_service: string
  note_book_grade_flag: boolean
  disability_grade: number
  note_book_grade: number
  disability_grade_text: string
  family_structure: string
  remarks: string

  things_of_interest: string
  thing_to_watchout: string
  email: string
  password: string
  certificate_info: {
    beneficiary_flag: string
    start_date: string
    certificate_original: string
    number: string
    provision_city_id: number
    service_type: number
    physical_disability_flag: boolean
    intellectual_disability_flag: boolean
    mental_disability_flag: boolean
    development_disability_flag: boolean
    brain_disfunction_flag: boolean
    disabled_child_support_flag: boolean
    incurable_disease_flag: boolean
    is_benefit_days_in_month: boolean
    benefit_payment_amount: string
    benefit_payment_amount2: string
    application_period_input_flag: boolean
    benefit_start_date: string | null
    benefit_end_date: string | null
    provision_start_date: string | null
    provision_end_date: string | null
    consultation_support_office_id: 1
    consultation_support_specialist: string
    burden_upper_limit_monthly_amount: null
    applicable_start_date: string | null
    applicable_end_date: string | null
    [x: string]: any
    showEmail: boolean
    employment_transition_cooperation: boolean
    employment_transition_cooperation_date: string | null
  }
  [x: string]: any
}

const validation = {
  username: yup
    .string()
    .required(t("Required"))
    .max(200, "Please enter number within 200 characters."),
  furigana: yup
    .string()
    .required(t("Required"))
    .max(200, "Please enter number within 200 characters.")
    .matches(/^[ぁ-んー　| ]*$/, t("※Hiragana is incorrect")),
  dob: yup.string().required(t("Required")),
  gender: yup.string().required(t("Required")),
  phone: yup.string().max(30, "Please enter number within 30 characters."),
  pobox: yup
    .string()
    .required(t("Required"))
    .max(8, t("(Half-width numbers and hyphens (-) within 8 characters)")),

  pref_id: yup.number().required(t("Required")).typeError(t("Enter number")),
  address: yup
    .string()
    .required(t("Required"))
    .max(200, "Please enter number within 200 characters."),
  wage_style: yup.string().required(t("Required")),
  certificate_info: yup
    .object()
    .nullable()
    .shape({
      beneficiary_flag: yup.string(),
      start_date: yup
        .string()
        .when("beneficiary_flag", ([beneficiary_flag], schema) => {
          return +beneficiary_flag === 1
            ? schema.required(t("Required"))
            : schema.nullable()
        }),
      certificate_original: yup.string().nullable(),
      number: yup
        .number()
        .transform((value, originalValue) => {
          return originalValue === "" ? null : value
        })
        .when("beneficiary_flag", ([beneficiary_flag], schema) => {
          return +beneficiary_flag === 1
            ? schema.required(t("Required")).typeError(t("Enter number"))
            : schema.nullable()
        }),
      service_type: yup
        .string()
        .when("beneficiary_flag", ([beneficiary_flag], schema) => {
          return +beneficiary_flag === 1
            ? schema.required(t("Required")).typeError(t("Enter number"))
            : schema.nullable()
        }),
      burden_upper_limit_monthly_amount: yup
        ?.number()
        .when("beneficiary_flag", ([beneficiary_flag], schema) => {
          return +beneficiary_flag === 1
            ? schema.required(t("Required"))
            : schema.nullable()
        }),
    }),
  email: yup
    .string()
    .required(t("Required"))
    .max(50, "(半角英数字4文字以上50字未満)")
    .min(4, "(半角英数字4文字以上50字未満)"),
  password: yup
    .string()
    .min(6, "(半角英数字6文字以上16字未満)")
    .max(16, "(半角英数字6文字以上16字未満)")
    .when("showEmail", ([showEmail], schema) => {
      return !showEmail ? schema.nullable() : schema.required(t("Required"))
    }),
}
export const UserManagementValidationSchema = yup.object().shape(validation)

export const UserManagementValidation = yup.object().shape({
  ...validation,
  hourly_wage: yup
    .number()
    .required(t("Required"))
    .typeError(t("Enter number")),
})

export type UserComponentFormProps = {
  values?: UserManagementInitialValues
  setFieldValue?: (field: string, value: any) => void
  errors?: any
  touched?: any
  id?: string | null
  handleBlur?: any
  recipientCertificate?: boolean
  wageTitle?: string
  setWageTitle?: (string) => void
}
