import { Dayjs } from "dayjs"
import { t } from "i18next"
import * as Yup from "yup"
export type PlanType = "draft" | "non-draft"
export interface PlanFormFields {
  user_id: string | number
  recipient_number: string | null
  facility_id: string | null
  facility_admin: string | null
  use_service_id: string | null
  create_count: number | string
  agree_date: Dayjs | null
  start_date: Dayjs | null
  end_date: Dayjs | null
  staff_id: string | null
  support_pran_draft_type?: boolean
  user_family_hope?: string | null
  support_policy?: string | null
  long_term_goal?: string | null
  short_term_goal?: string | null
  goto_meet_flag?: string | null
  goto_meet_place?: string | null
  take_flag?: string | null
  take_place?: string | null
  remarks?: string | null
  isDraft: false
  furigana?: string | null
}
export interface ActualPlanSearchParams {
  keyword?: string
  user_id?: number
  service?: any
  facilityIds?: string[] | []
  date?: any
  expiry_date?: any
  display_settings?: string
  status?: string[] | []
  page?: number
  pageSize?: number
  user_without_services?: string
}
export interface PlanSearchParams extends ActualPlanSearchParams {
  date_from?: string
  date_to?: string
  expiry_date_from?: string
  expiry_date_to?: string
  hiragana?: string
}
interface staff {
  id: number
  staff_name: string
}
interface IndividualPlanCommon {
  id: number
  key?: number
  created_datetime: string
  created_by?: string
  updated_datetime?: string
  updated_by?: string
  deleted_datetime?: string | null
  deleted_by?: string
  is_deleted?: boolean
  company_id: number
  user_id: number
  facility_id: number
  create_count: number
  report_create_date: number | null
  start_date: string | null
  end_date: string | null
  staff_id: string | null
  staff: staff | null
  support_plan_draft_type: string | null
  child_family_hope: string | null
  support_policy: string | null
  long_term_goal: string | null
  short_term_goal: string | null
  goto_meet_flag: string | null
  goto_meet_place: string | null
  take_flag: string | null
  take_place: string | null
  remarks: string | null
  agree_date: string | null
  draft_save_flag: string | null
  use_service_id: number | null
  child_info?: any
  facility?: any
  digital_signature_image?: string
  child_digital_signature_image?: string
  manager_digital_signature_image?: string
  explanation_date_digital_signature_image?: string
  consent_date_digital_signature_image?: string
}

export const PlanFormSchema = Yup.object({
  user_id: Yup.number().required(t("Required")).nullable(),
  facility_id: Yup.number()
    .min(1, t("Required"))
    .required(t("Required"))
    .nullable(),
  use_service_id: Yup.number().required(t("Required")).nullable(),
  create_count: Yup.number().required(t("Required")),
  goto_meet_place: Yup.string().nullable().max(100, t("(Within 100 char)")),
  take_place: Yup.string().nullable().max(100, t("(Within 100 char)")),
})

export interface UsersWhoNeedMonitoringParams {
  user_id?: number
  service?: string
  facility?: string
  date?: string
  expiry_date?: string
  display_settings?: string
  status?: string
  page?: number
  pageSize?: number | string
  user_without_services?: string
}

interface staff {
  id: number
  staff_name: string
}

export interface IndividualSupportPlanItemObj {
  id: number
  created_datetime: string | null
  created_by: string | null
  updated_datetime: string | null
  updated_by: string | null
  deleted_datetime: string | null
  deleted_by: string | null
  is_deleted: boolean
  company_id: number | null
  child_support_pran_draft_id: number | null
  title: string
  support_content?: {
    achievement_goals: string
    achievement_time: string
    support_and_considerations: string
  }
}
export interface IndividualSupportWeeklyScheduleObj {
  user_support_plan_id?: number
  comment?: string
  company_id: number
  created_by: string
  created_datetime: string
  deleted_by: string
  deleted_datetime?: string | null
  end_time: string | null
  id: number
  is_deleted?: boolean | null
  start_time: string
  updated_by: string
  updated_datetime: string
  week_no: number
}
export interface IndividualSupportPlanResponse extends IndividualPlanCommon {
  plan_items: IndividualSupportPlanItemObj[]
  weekly_schedules: IndividualSupportWeeklyScheduleObj[]
}

export interface IndividualSupportDetailResponse {
  childSupport: IndividualPlanCommon
  childSupportItems: IndividualSupportPlanItemObj[]
  childSupportSchedule: IndividualSupportWeeklyScheduleObj[]
}

export interface PlanSignatureResponseObj {
  digital_signature_image?: string //parent signature
  child_digital_signature_image?: string
  manager_digital_signature_image?: string
  manager_date_digital_signature_image?: string
  explanation_date_digital_signature_image?: string
  consent_date_digital_signature_image?: string
  user_id: number
  planId: number
  facility_id: number
}
export interface PlanOperationData {
  display_content: any[]
  consent_date: any[]
  parent_sign: any[]
  statementText: {
    consentStatement: string
    consentStatementForChild: string
  }
  print_settings: string
}
