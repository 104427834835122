import { t } from "i18next"
import * as yup from "yup"
export interface DailyBusinessReportListColumn {
  [x: string]: any
}
export const DailyBusinessReportFormSchema = yup.object().shape({
  facility_id: yup.string().required(t("Required")),
  date: yup.string().required(t("Required")),
  daily_business_support_report: yup.array().of(
    yup
      .object()
      .shape({
        start_time_hr: yup.string().nullable(),
        start_time_min: yup.string().nullable(),
        end_time_hr: yup.string().nullable(),
        end_time_min: yup.string().nullable(),
      })
      .test(
        "is-end-time-greater",
        t("End date should be greater than start date"),
        function (value) {
          const { start_time_hr, start_time_min, end_time_hr, end_time_min } =
            value
          const startTime =
            parseInt(start_time_hr, 10) * 60 + parseInt(start_time_min, 10)
          const endTime =
            parseInt(end_time_hr, 10) * 60 + parseInt(end_time_min, 10)

          if (
            isNaN(startTime) ||
            isNaN(endTime) ||
            start_time_hr === "" ||
            start_time_min === "" ||
            end_time_hr === "" ||
            end_time_min === ""
          ) {
            return true
          }

          return startTime < endTime
        },
      ),
  ),
})
