import { t } from "i18next"
import * as Yup from "yup"
export interface MinutesOfMeetingOfPicColumn {
  [x: string]: any
}

export const MinutesOfMeetingOfPicValidationSchema = Yup.object().shape({
  start_date: Yup.string().required(t("Required")),
  report_create_date: Yup.string().required(t("Required")),
  user_id: Yup.number().required(t("Required")),
  facility_id: Yup.number().required(t("Required")),
  service_type: Yup.number().required(t("Required")),
  create_count: Yup.number()
    .required(t("Required"))
    .min(1, t("Required"))
    .max(999999, t("Required"))
    .typeError(t("Required")),
  creator_id: Yup.number()
    .typeError(t("Required"))
    .required(t("Required"))
    .min(1),
  conference_participation_facilities: Yup.number()
    .typeError(t("Required"))
    .min(1)
    .required(t("Required")),
})
export interface minutesOfMeetingOfPicForm {
  facility_name: string
}
