import { t } from "i18next"
import * as yup from "yup"

export interface UserFacilityFormField {
  work_category_id: number
  work_name: string
}

export const UserFacilityFormSchema = yup.object().shape({
  work_category_id: yup.number().required(t("Required")),
  work_name: yup.string().required(t("Required")),
})
export interface UserFacilityResponse {
  work_category_id: number
  work_name: string
}
export interface UserFacilityResponseObj {
  data: UserFacilityResponse
}

export interface UserFacilityColumn {
  [x: string]: any
}
