import { t } from "i18next"
import * as yup from "yup"

const NO_HYPHEN_REGEX = /^[-0-9]*$/
export interface JointFacilityTableDataType {
  id: string | number
  facility_name: string
  facility_name_furigana?: string
}

export interface JointFacilityFormValues {
  facility_name: string
  facility_name_furigana: string
  facility_number: number | null
  other_service_type_text: string | null
  service_type01: boolean | any
  service_type02: boolean | any
  service_type03: boolean | any
  other_service_type: boolean | any
  fax_no: string
  po_box: string
  prefecture: number
  address: string
  pic: string
  email: string
  zip_code?: string
  tel_no?: string
  remark_text?: string
  service_provided?: string
}

export const JointFacilityFormSchema = yup.object().shape({
  facility_name: yup
    .string()
    .required(t("Please enter"))
    .max(50, t("(within 50 char)")),
  facility_name_furigana: yup
    .string()
    .matches(/^[ぁ-んー　| ]*$/, t("※Hiragana is incorrect"))
    .max(100, t("(within 100 char)")),
  facility_number: yup
    .string()
    .required(t("Please enter"))
    .typeError(t("Please enter a number"))
    .max(30, t("(Within 30 char)")),
  address: yup.string().max(250, t("(Within 250 char)")),
  tel_no: yup
    .string()
    .max(50, t("(Numbers & hyphens 50 char)"))
    .matches(NO_HYPHEN_REGEX, t("Phone numbers can only be number and hypens")),
  zip_code: yup
    .string()
    .max(8, t("(Within 8 char)"))
    .matches(/([0-9]{3}-[0-9]{4})/, t("Invalid PostBox")),
  fax_no: yup
    .string()
    .max(50, t("(Numbers & hyphens 50 char)"))
    .matches(NO_HYPHEN_REGEX, t("Fax can only be number and hypens")),
  remark_text: yup.string(),
  prefecture: yup.string().required(t("Please select")),
  service_provided: yup
    .string()
    .when(
      [
        "service_type01",
        "service_type02",
        "service_type03",
        "other_service_type",
      ],
      {
        is: (
          service_type01,
          service_type02,
          service_type03,
          other_service_type,
        ) => {
          return (
            service_type01 ||
            service_type02 ||
            service_type03 ||
            other_service_type
          )
        },

        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required(t("Please select")),
      },
    ),
  pic: yup.string().max(100, t("(within 100 char)")).nullable(),
  email: yup
    .string()
    .email(t("The email address is not valid"))
    .max(100, t("(within 100 char)")),
})
