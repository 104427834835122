import { t } from "i18next"
import * as yup from "yup"

export interface MealMasterFormField {
  name: string
  cost: number | null
  id?: number
  meal_using_facility: Array<number>
}
export const MealMasterFormFieldSchema = yup.object().shape({
  name: yup.string().required(t("Please enter")).max(50),
  cost: yup.number().required(t("Please select")).max(99999999999),
})

export interface MealMasterResponse {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: null
  deleted_by: string
  is_deleted: false
  company_id: number
  name: string
  cost: number
  meal_using_facility: Array<{ facility_id: number }>
}
export interface MealMasterAmountPerUseField {
  day: number
  day_of_week: string
  name: string
  price: number
  isEditing?: boolean
  is_holiday?: boolean
  is_scheduled?: boolean
}

export interface MealInfoField {
  meal_id: number
  user_id?: number
  date?: any
  facility_id?: any
}

export interface MealAmountPerUserParams {
  page: number
  pageSize: number
  month: number
  year: number
  user_id?: number
  facility_id?: any
}
export interface MealAmountPerUserDetailParams {
  month: number
  year: number
  userId: number
}
