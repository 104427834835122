import { Dayjs } from "dayjs"
import * as Yup from "yup"
export interface StaffMasterOperationOptions {
  page?: number
  keyword?: string
  facility?: any[]
  occupation?: any[]
  joining_date_from?: Dayjs | null
  joining_date_to?: Dayjs | null
  retirement_date_from?: Dayjs | null
  retirement_date_to?: Dayjs | null
  work_style?: any[]
  joining_date?: any
  retirement_date?: any
}
export interface StaffMasterTableDataType {
  [x: string]: any
}

export interface StaffBasicInfoValues {
  staff_name: string
  staff_name_furiganaame: string | null
  joining_date?: string | null
  retirement_date?: string | null
}
export const StaffBasicInfoSchema = Yup.object().shape({
  staff_name: Yup.string()
    .required("Please enter")
    .max(50, "Please enter number within 50 characters."),
  staff_name_furiganaame: Yup.string()
    .nullable()
    .matches(/^[ぁ-んー　| ]*$/, "※Hiragana is incorrect")
    .max(50, "Please enter number within 50 characters."),
})
export interface StaffResponse {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: null
  deleted_by: string
  is_deleted: boolean
  company_id: number
  staff_name: string
  staff_name_furiganaame: string
  joining_date: string
  retirement_date: string
  staff_qualification: any[]
  staff_experience: any[]
  staff_facility: any[]
}
export interface StaffMasterListResponse {
  count?: number
  data?: StaffResponse[]
}
