import { t } from "i18next"
import * as yup from "yup"

export interface UserWhoNeedsMonitoringFormField {
  work_category_id: number
  work_name: string
}

export const UserWhoNeedsMonitoringFormSchema = yup.object().shape({
  work_category_id: yup.number().required(t("Required")),
  work_name: yup.string().required(t("Required")),
})
export interface UserWhoNeedsMonitoringResponse {
  work_category_id: number
  work_name: string
}
export interface UserWhoNeedsMonitoringResponseObj {
  data: UserWhoNeedsMonitoringResponse
}

export interface UserWhoNeedsMonitoringColumn {
  [x: string]: any
}
