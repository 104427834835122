import * as Yup from "yup"
export interface ListOfMunicipalSubsidyColumn {
  [x: string]: any
}

export interface ListOfMunicipalSubsidyValues {
  facility_id: number
  year: string
  month: string
  user_id: number
  management_result: number
  municipality_number: number
  burden_amount: number
  subsidy_ratio: number
  municipality_billing_amount: number
  id?: number
}
export const ListOfMunicipalSubsidySchema = Yup.object().shape({
  facility_id: Yup.number().required("Required"),
  year: Yup.number().required("Required"),
  month: Yup.number().required("Required"),
  user_id: Yup.number().required("Required"),
})
