import * as Yup from "yup"
export interface UserActivityFormValues {
  date: string
  facility_id: string
  article_title: string
  release_flag: boolean
  article_content_1: string
  article_content_2: string
  article_content_3: string
  article_content_4: string
  article_content_5: string
  article_content_6: string
  article_content_7: string
  article_content_8: string
  article_content_9: string
  article_content_10: string
  article_content_img_1: string
  article_content_img_2: string
  article_content_img_3: string
  article_content_img_4: string
  article_content_img_5: string
  article_content_img_6: string
  article_content_img_7: string
  article_content_img_8: string
  article_content_img_9: string
  article_content_img_10: string
  thumbnail1_is_image: boolean
  thumbnail2_is_image: boolean
  thumbnail3_is_image: boolean
  thumbnail4_is_image: boolean
  thumbnail5_is_image: boolean
  thumbnail6_is_image: boolean
  thumbnail7_is_image: boolean
  thumbnail8_is_image: boolean
  thumbnail9_is_image: boolean
  thumbnail10_is_image: boolean
  image1_filename: string
  image2_filename: string
  image3_filename: string
  image4_filename: string
  image5_filename: string
  image6_filename: string
  image7_filename: string
  image8_filename: string
  image9_filename: string
  image10_filename: string
}
export const UserActivityFormSchema = Yup.object().shape({
  date: Yup.string().required("Required"),
  article_title: Yup.string().required("Required"),
  article_content_1: Yup.string().required("Required"),
  article_content_2: Yup.string().required("Required"),
  article_content_3: Yup.string().required("Required"),
  article_content_4: Yup.string().required("Required"),
  article_content_5: Yup.string().required("Required"),
  article_content_6: Yup.string().required("Required"),
  article_content_7: Yup.string().required("Required"),
  article_content_8: Yup.string().required("Required"),
  article_content_9: Yup.string().required("Required"),
  article_content_10: Yup.string().required("Required"),
})
