import { t } from "i18next"
import * as yup from "yup"

export interface WorkDetailsMasterFormField {
  work_category_id: number
  work_name: string
}

export const WorkDetailsMasterFormSchema = yup.object().shape({
  work_category_id: yup.number().required(t("Please select")),
  work_name: yup.string().required(t("Please enter")),
})
export interface WorkDetailsMasterResponse {
  work_category_id: number
  work_name: string
}
export interface WorkDetailsMasterResponseObj {
  data: WorkDetailsMasterResponse
}

export interface WorkDetailColumn {
  [x: string]: any
}
