import { t } from "i18next"
import * as yup from "yup"
export interface MonitoringSearchParams {
  page?: number
  pageSize?: number
  user_id?: any
  facility_ids: any
  display_setting: any
  period_start_date?: any
  period_end_date?: any
  draft_save_flg: any
}

export interface MonitoringReturnChild {
  count?: number
  data?: any
}
export interface MonitoringFormValues {
  user_id: any
  furigana?: any
  facility_id: any
  service_type: any
  create_count: any
  report_create_date: any
  staff_id: any
  user_monitoring_type?: any
  remark: any
  family_hope: any
  long_term_goal_study: any
  user_hope: any
  concern_hope: any
  short_term_goal_study: any
  draft_save_flag: any
  user_monitoring_content: any
  long_term: any
  short_term: any
}
export const MonitoringFormValuesSchema = yup.object().shape({
  user_id: yup.string().required(t("Required")),
  facility_id: yup.number().min(1).required(t("Required")),
  service_type: yup.string().required(t("Required")),
  create_count: yup.string().required(t("Required")),
  report_create_date: yup.date().required(t("Required")),
  child_monitoring_type: yup.string(),
  concern_hope: yup.string(),
  remark: yup.string(),
  family_hope: yup.string(),
  long_term_goal_study: yup.string(),
  user_hope: yup.string(),
  short_term_goal_study: yup.string(),
  draft_save_flag: yup.boolean(),
})
