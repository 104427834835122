import { t } from "i18next"
import * as Yup from "yup"

export const maxLimitMessage = (maxLimit: number) => {
  return t("Please enter number within {{maxLimit}} characters", {
    maxLimit,
  })
}
// Check if the number is in scientific notation like 1e2
export const checkExponentialNotation = (value: string) => {
  if (typeof value === "number" && !isNaN(value)) {
    // Check if the number is in scientific notation
    const stringValue = String(value)
    if (stringValue.includes("e") || stringValue.includes("E")) {
      return false
    }
  }
  if (
    typeof value === "string" &&
    (value.includes("e") || value.includes("E"))
  ) {
    return false
  }
  return true
}
const PHONE_NO_REGEX = /^(?=.*[0-9])[-0-9]+$/

export const FacilityInformationValidationSchema = Yup.object().shape({
  business_number: Yup.string()
    .max(15, t("Please enter number within 15 characters"))
    .required(t("Required")),
  facility_name: Yup.string()
    .max(100, t("(within 100 char)"))
    .required(t("Required")),
  facility_type: Yup.number().required(t("Required")),
  capacity: Yup.number()
    .required(t("Required"))
    .min(0, t("Please enter a positive number")),
  mail_address: Yup.string()
    .email(t("Enter Email"))
    .max(100, t("(Within 100 char)"))
    .required(t("Required")),
  hospital_po_box: Yup.string().matches(
    /([0-9]{3}-[0-9]{4})/,
    t("Invalid PostBox"),
  ),
  hospital_pref_id: Yup.number(),
  municipality_address: Yup.string().max(200, t("(Within 200 char)")),
  post_box_number: Yup.string()
    .nullable()
    .max(8, "(Within 8 char)")
    .matches(/([0-9]{3}-[0-9]{4})/, "Invalid PostBox"),
  contact_phone: Yup.string()
    .max(100)
    .matches(
      PHONE_NO_REGEX,
      "(Please enter within 30 characters with numbers & hyphens only)",
    ),
  bcp_phone: Yup.string()
    .max(100)
    .matches(
      PHONE_NO_REGEX,
      "(Please enter within 30 characters with numbers & hyphens only)",
    ),
  hospital_phone: Yup.string()
    .max(100)
    .matches(
      PHONE_NO_REGEX,
      "(Please enter within 30 characters with numbers & hyphens only)",
    ),
  facility_name_furigana: Yup.string()
    .max(100, t("(within 100 char)"))
    .matches(/^[ぁ-んー　| ]*$/, t("※Hiragana is incorrect")),
  financial_institution_name_kana: Yup.string()
    .max(50, t("(within 50 char)"))
    .matches(/^[ァ-ヶー　| ]*$/, t("※Katakana is incorrect")),
  financial_institution_branch_name_kana: Yup.string()
    .max(50, t("(within 50 char)"))
    .matches(/^[ァ-ヶー　| ]*$/, t("※Katakana is incorrect")),
  account_holder_name_kana: Yup.string()
    .max(50, t("(within 50 char)"))
    .matches(/^[ァ-ヶー　| ]*$/, t("※Katakana is incorrect")),
  consign_name_kana: Yup.string()
    .max(50, t("(within 50 char)"))
    .matches(/^[ァ-ヶー　| ]*$/, t("※Katakana is incorrect")),
})

export interface facilityInformationFormField {
  business_number: string
  facility_name: string
  facility_name_furigana: string
  facility_type: number
  grade: number
  physical_disability_support_flag: boolean
  intellectual_disability_support_flag: boolean
  mental_disability_support_flag: boolean
  development_disability_support_flag: boolean
  brain_disfunction_support_flag: boolean
  disabled_child_support_flag: boolean
  incurable_disease_support_flag: boolean
  meal_provision_flag: boolean
  capacity: number
  mail_address: string
  show_my_page_info: boolean
  contact_po_box: string
  address: string
  contact_phone: string
  fax: string
  bcp_evacuation: string
  bcp_pref_id: number
  remaining_address: string
  bcp_phone: string
  hospital_name: string
  hospital_po_box: string
  hospital_pref_id: number
  municipality_address: string
  hospital_phone: string
  note: string
  pay_slip_alert_date: string
  corporate_name: string
  representative_name: string
  administrator_name: string
  invoice_title: string
  invoice_full_name: string
  financial_institution_name: string
  financial_institution_branch_name: string
  financial_institution_name_kana: string
  financial_institution_branch_name_kana: string
  account_type: number
  account_holder_name: string
  account_holder_name_kana: string
  consign_number: string
  consign_name_kana: string
  bank_no: string
  branch_no: string
  facility_wage_setting?: FacilityWageSetting[]
  facility_wage_setting2?: FacilityWageSetting[]
}

export interface FacilityInformationColumn {
  [x: string]: any
}

// ____________________________________________________________________________
export type Facilities = Facility[]

export interface Facility {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  business_number: string
  facility_name: string
  facility_name_furigana: string
  facility_type: number
  grade: string
  physical_disability_support_flag: boolean
  intellectual_disability_support_flag: boolean
  mental_disability_support_flag: boolean
  development_disability_support_flag: boolean
  brain_disfunction_support_flag: boolean
  disabled_child_support_flag: boolean
  incurable_disease_support_flag: boolean
  meal_provision_flag: boolean
  capacity: number
  support_visits_nurseries_flag: boolean
  mail_address: string
  show_my_page_info: boolean
  contact_po_box: string
  address: string
  contact_phone: string
  fax: string
  bcp_evacuation: string
  bcp_pref_id: number
  remaining_address: string
  bcp_phone: string
  hospital_name: string
  hospital_po_box: string
  hospital_pref_id: number
  municipality_address: string
  hospital_phone: string
  note: string
  pay_slip_alert_date: string
  facility_wage_setting: FacilityWageSetting[]
  corporate_name: string
  representative_name: string
  administrator_name: string
  invoice_title: string
  invoice_full_name: string
  financial_institution_name: string
  financial_institution_branch_name: string
  financial_institution_name_kana: string
  financial_institution_branch_name_kana: string
  account_type: number
  account_holder_name: string
  account_holder_name_kana: string
  consign_number: string
  consign_name_kana: string
  branch_no: string
  bank_no: string
  is_draft: boolean
  value: string
  label: string
}

export interface FacilityWageSetting {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  facility_id: number
  item_type: string
  name: string
}
