import { t } from "i18next"
import * as yup from "yup"

export const ShiftUserMasterInfoSchema = yup.object().shape({
  attendance_shift_name: yup
    .string()
    .required(t("Required"))
    .max(50, t("Please enter number within 50 characters.")),
  number_of_employees_include_flg: yup.string(),
  facilities: yup.array().min(1, t("Required")),
  work_time: yup.object().when("number_of_employees_include_flg", {
    is: (val) => {
      return val === "false"
    },
    then: (schema) =>
      schema
        .shape({
          start_time: yup.object().shape({
            hr: yup.string().required(t("Required")),
            min: yup.string().required(t("Required")),
          }),
          end_time: yup.object().shape({
            hr: yup.string().required(t("Required")),
            min: yup.string().required(t("Required")),
          }),
        })
        .test(
          "time",
          t("Working hour end time can't be earlier than start time"),
          (value) => {
            const { start_time, end_time } = value
            const startInMinute =
              (start_time?.hr ? +start_time?.hr : 0) * 60 +
              (start_time?.min ? +start_time?.min : 0)
            const endInMinute =
              (end_time?.hr ? +end_time?.hr : 0) * 60 +
              (end_time?.min ? +end_time?.min : 0)
            return endInMinute >= startInMinute
          },
        ),
    otherwise: (schema) =>
      schema.shape({
        start_time: yup.object().shape({
          hr: yup.string().nullable().notRequired(),
          min: yup.string().nullable().notRequired(),
        }),
        end_time: yup.object().shape({
          hr: yup.string().nullable().notRequired(),
          min: yup.string().nullable().notRequired(),
        }),
      }),
  }),

  attendance_rest_mins: yup
    .string()
    .matches(/^\d+$/, t("mins (max 3 numbers)"))
    .when(["number_of_employees_include_flg", "break_settings"], {
      is: (flg, setting) => flg === "false" && setting === "true",
      then: (schema) => schema.required(t("Required")),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
  remarks: yup.string().max(20),
})

export interface ShiftUserMasterInitialValues {
  attendance_shift_name: string | null
  facilities: any[]
  employment_type_id: any[]
  work_time: {
    start_time: {
      hr: string | null
      min: string | null
    }
    end_time: {
      hr: string | null
      min: string | null
    }
  }
  attendance_rest_mins: string | null
  remarks: string | null
  number_of_employees_include_flg: boolean | null
}
