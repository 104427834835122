import { t } from "i18next"
import * as Yup from "yup"

export interface UserWorkInformationCreateEditRequestType {
  employment_date: string
  user_id?: number
  service_type: number
  used_joint_facility: string
  certificate_employment: string
}
export const EmploymentCertificateBasicInformationSchema = Yup.object().shape({
  service_type: Yup.mixed().nullable(),
  used_joint_facility: Yup.string().required(t("Required")),
  certificate_employment: Yup.string().required(t("Required")),
  employment_date: Yup.string().required(t("Required")),
})

export interface UserWorkInformationStatusRequestType {
  employment_place: string
  contacted_date: string
  user_id?: number
  status_of_user: number
  remarks: ""
  method_of_contact: null
  employment_certificate: string
  place_of_employment: number
  employment_place_date: string
}

export type User = {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: string | null
  deleted_by: string
  is_deleted: boolean
  company_id: number
  username: string
  furigana: string
  dob: string
  gender: string
  phone: string
  pobox: string
  pref_id: number
  address: string
  wage_style: string
  hourly_wage: number
  parent_id: number
  image: string
  financial_institution_name_kana: string
  branch_name_kana: string
  financial_institute_code: string
  branch_code: string
  account_type: number
  account_number: string
  account_holder_name: string
  customer_code: string
  email_address: string
  email_address_2: string
  attend_flag: boolean
  leave_flag: boolean
  emergency_contact_name1: string
  emergency_contact_phone1: string
  emergency_contact_name2: string
  emergency_contact_phone2: string
  emergency_contact_remarks: string | null
  contract_doc: string
  hospital_name: string
  doctor_name: string
  hospital_ph: string
  allergy_flag: boolean
  allergy_remarks: string
  symptoms: string
  medical_info: string
  other_service: string
  note_book_grade_flag: boolean
  note_book_grade: number
  disability_grade_text: string
  disability_grade: number
  family_structure: string
  remarks: string
  things_of_interest: string
  thing_to_watchout: string
  email: string
  user_certificate: string | null
  certificate_id: number
  active_contract_id: number
}

export type EmploymentCompany = {
  industry_name: string
  id: number
}

export type UserWorkInformationStatus = {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: string | null
  deleted_by: string
  is_deleted: boolean
  company_id: number
  employment_place: string
  contacted_date: string
  user_id: number
  status_of_user: number
  method_of_contact: number
  remarks: string
}

export const EmploymentCertificateStatusSchema = Yup.object().shape({
  service_type: Yup.mixed().nullable(),
  contacted_date: Yup.string().required(t("Please select")),
  status_of_user: Yup.number().nullable().required(t("Please select")),
  method_of_contact: Yup.number().nullable(),
  remarks: Yup.string().when(["status_of_user"], {
    is: (status_of_user) => status_of_user === 4,
    then: (schema) => schema.required(t("Please enter")),
    otherwise: (schema) => schema.notRequired().nullable(),
  }),
  employment_certificate: Yup.string().nullable(),
  place_of_employment: Yup.number().required("Please select"),
  employment_place: Yup.string().when(["place_of_employment"], {
    is: (place_of_employment) => place_of_employment === 2,
    then: (schema) =>
      schema.required(t("Please enter")).max(200, t("(within 200 char)")),
    otherwise: (schema) => schema.notRequired().nullable(),
  }),
  employment_place_date: Yup.string().when(["place_of_employment"], {
    is: (place_of_employment) => place_of_employment === 2,
    then: (schema) => schema.required(t("Please select")),
    otherwise: (schema) => schema.notRequired().nullable(),
  }),
})
export type DataType = {
  key: string
  name: string
  place_of_employment: string
  current_status_of_user: string
}

export type EmploymentCertificate = {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: string | null
  deleted_by: string
  is_deleted: boolean
  company_id: number
  service_type: number
  employment_date: string
  user_id: number
  used_joint_facility: string
  certificate_employment: string
  user: User
  user_work_information_status: UserWorkInformationStatus[]
  user_contract?: { facility_id: number }
}

export type EmploymentCertificateListResponse = {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: string | null
  deleted_by: string
  is_deleted: boolean
  company_id: number
  service_type: number
  employment_date: string
  user_id: number
  employment_place: string
  employment_certificate?: string
  user_name: string
  furigana: string
  status_of_user: number
  user_contract?: { facility_id: number }
}

export type EmploymentCertificateApiValues = {
  count: number
  data: EmploymentCertificate[]
}

export type EmploymentCertificateListApiValues = {
  count: number
  data: EmploymentCertificateListResponse[]
}
