import { t } from "i18next"
import * as Yup from "yup"
export interface ProvisionCityFormValues {
  // prefecture_name: string
  provision_city_name: string
  municipality_number: number
  subsidy_municipality_number: number
  pic: string
  phone?: string
  mayor_name?: string
  email?: string
  po_box: string
  address: string
  zip_code?: string
}
export const ProvisionCityFormSchema = Yup.object().shape({
  provision_city_name: Yup.string()
    .required(t("Please enter"))
    .max(50, t("Please enter number within 50 characters.")),
  pic: Yup.string().max(100, t("Please enter number within 100 characters.")),
  mayor_name: Yup.string().max(
    100,
    t("Please enter number within 100 characters."),
  ),
  municipality_number: Yup.number()
    .required(t("Please enter"))
    .max(999999, t("Please enter number within 6 characters."))
    .typeError("Please enter number"),
  subsidy_municipality_number: Yup.number()
    .typeError(t("Please enter number"))
    .max(999999, t("Please enter number within 6 characters."))
    .nullable()
    .optional(),

  phone: Yup.string()
    .typeError("Please enter phone number")
    .max(30, t("Please enter number within 30 characters"))
    .notRequired()
    .nullable(),
  email: Yup.string().email(t("Enter a valid Email")).notRequired().nullable(),
  zip_code: Yup.string()
    .nullable()
    .max(8, t("(within 8 char)"))
    .matches(/([0-9]{3}-[0-9]{4})/, t("Invalid PostBox")),
  address: Yup.string()
    .nullable()
    .notRequired()
    .max(200, t("Please enter number within 200 characters")),
})
