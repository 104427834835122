import { t } from "i18next"
import * as yup from "yup"

export interface AutoEmailSendingFormField {
  mail_type: number
  mail_address: string
  mail_sender: string
  mail_subject: string
  mail_body: string
  mail_body_add: string
  mail_footer: string
}

export const AutoEmailSendingFormSchema = yup.object().shape({
  mail_address: yup.string().required(t("Required")),
  mail_sender: yup.string().required(t("Required")),
  mail_subject: yup.string().required(t("Required")),
})
export interface AutoEmailSendingResponse {
  form_title: string
  sender_email: string
  sender_name: string
  subject: string
  email_body: string
  end_of_email_body: string
  email_footer: string
}
export interface AutoEmailSendingResponseObj {
  data: AutoEmailSendingResponse
}

export interface AutoEmailSendingColumn {
  [x: string]: any
}
