import { t } from "i18next"
import * as yup from "yup"
import { PHONE_NO_REGEX } from "../constants"
export interface ConsultationsSupportOfficeFormField {
  consultation_support_name: string
  consultation_support_name_hiragana: string
  consultation_support_number: string
  address: string
  phone_number: string
  fax_number: string
  main_target_disability: Array<string>
  pic_name: string
  email_address: string
  pref_id: string
  post_box_number?: string
}
export const ConsultationsSupportOfficeFormSchema = yup.object().shape({
  consultation_support_name: yup
    .string()
    .max(50, t("(Within 50 char)"))
    .required(t("Please enter")),
  consultation_support_name_hiragana: yup
    .string()
    .max(100, t("(Within 100 char)"))
    .matches(/^[ぁ-んー　| ]*$/, t("※Hiragana is incorrect")),
  consultation_support_number: yup.string().max(30, t("(Within 30 char)")),
  address: yup.string().max(200, t("(Within 200 char)")),
  pic_name: yup.string().max(50, t("(Within 50 char)")),
  email_address: yup
    .string()
    .email(t("Enter Email"))
    .max(100, t("(Within 100 char)")),
  phone_number: yup
    .string()
    .max(30, t("(Within 30 char)"))
    .matches(PHONE_NO_REGEX, {
      message: t("(Max 30 with num & hyphens)"),
      excludeEmptyString: true,
    })
    .test(
      "max-hyphens",
      t("Maximum of 3 hyphens allowed"),
      (value) => !value || value.split("-").length < 5,
    ),
  fax_number: yup
    .string()
    .max(30, t("(Within 30 char)"))
    .matches(PHONE_NO_REGEX, {
      message: t("(Max 30 with num & hyphens)"),
      excludeEmptyString: true,
    })
    .test(
      "max-hyphens",
      t("Maximum of 3 hyphens allowed"),
      (value) => !value || value.split("-").length < 5,
    ),
  post_box_number: yup.string().nullable().max(8, t("(Within 8 char)")),
  pref_id: yup.string().required(t("Please select")),
})
export interface ConsultationsSupportOfficeResponse {
  address: string
  consultation_support_name: string
  consultation_support_name_hiragana: string
  consultation_support_number: string
  created_by: string
  created_datetime: string
  deleted_by: string
  deleted_datetime: null
  fax_number: string
  id: number
  is_deleted: boolean
  phone_number: string
  post_box_number: string
  pref_id: 2
  updated_by: string
  updated_datetime: string
}
export interface ConsultationsSupportOfficeResponseObj {
  data: ConsultationsSupportOfficeResponse
}
